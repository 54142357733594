import { isNumber } from 'lodash'
import Input, { BaseInput } from './Input'
import CurrencyFormat from 'react-currency-format'

type Props = {
  suffix?: string
}

const Currency: React.FC<BaseInput & Props> = ({ value, onChange, suffix = ' €', ...params }) => {
  const internalOnChange = (value: number) => {
    if (!!params.max && isNumber(params.max) && value > params.max) {
      onChange(params.max)
    } else {
      onChange(value)
    }
  }

  return (
    <CurrencyFormat
      customInput={Input}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale={true}
      value={value || ''}
      onValueChange={(v) => internalOnChange(v.floatValue)}
      returnRawChangeEvent
      {...params}
      suffix={suffix}
      type="text"
      textAlign="right"
    />
  )
}

export default Currency
