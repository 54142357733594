import { Property, Required } from './types'

export const kontakt: Property[] = [
  {
    name: 'ansprache',
    required: Required.NEEDED,
  },
  {
    name: 'vorname',
    required: Required.NEEDED,
  },
  {
    name: 'nachname',
    required: Required.NEEDED,
  },
  {
    name: 'geburtsdatum',
    required: Required.NEEDED,
  },
  {
    name: 'mail',
    required: Required.NEEDED,
  },
  {
    name: 'telefon',
    required: Required.OPTIONAL,
  },
  {
    name: 'mobilTelefon',
    required: Required.OPTIONAL,
  },
  {
    name: 'strasseHausnummer',
    required: Required.NEEDED,
  },
  {
    name: 'postleitzahl',
    required: Required.NEEDED,
  },
  {
    name: 'stadt',
    required: Required.NEEDED,
  },
  {
    name: 'land',
    required: Required.NEEDED,
  },
  {
    name: 'onliner',
    required: Required.NEEDED,
  },
]

export const steuer: Property[] = [
  {
    name: 'steuernummer',
    required: Required.OPTIONAL,
  },
  {
    name: 'steuerID',
    required: Required.NEEDED,
  },
]
