import moment from 'moment'
import { getDateInputFormat, getDateTimeInputFormat } from '../../../utility'
import { isNumber } from 'lodash'

export interface BaseInput extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  id?: string
  type?: string
  onChange: (value: any) => void
  label?: string | boolean
  placeholder?: string
  className?: string
  required?: boolean
  value: any
  autoComplete?: string
  returnRawChangeEvent?: boolean
  disabled?: boolean
  hiddenDisabled?: boolean
  max?: number
  useRef?: any
}

const Input: React.FC<BaseInput> = ({
  id,
  type = 'text',
  value,
  label,
  placeholder,
  onChange,
  required = false,
  className = '',
  autoComplete = 'off',
  returnRawChangeEvent = false,
  disabled = false,
  hiddenDisabled = false,
  max,
  useRef,
}) => {
  const prepValue = () => {
    if (!value) return ''
    if (type === 'date') {
      if (typeof value === 'object') {
        return getDateInputFormat(value)
      } else if (value.includes('T')) {
        return getDateInputFormat(new Date(value))
      }
      return value
    } else if (type === 'datetime-local') {
      if (typeof value === 'object') {
        return getDateTimeInputFormat(value)
      } else if (value.includes('T')) {
        return moment.utc(value).format('YYYY-MM-DDTHH:mm')
      }
      return value
    } else {
      return value
    }
  }

  const internalOnChange = (value: string) => {
    if (type === 'date') {
      const dateSplit = value.split('-')
      if (dateSplit.length === 3 && dateSplit[0].length > 4) {
        onChange(`${dateSplit[0].slice(0, 4)}-${dateSplit[1]}-${dateSplit[2]}`)
      } else {
        onChange(value)
      }
    } else {
      if (!!max && max > 0) {
        if (isNumber(value) && value > max) {
          onChange(max)
        }
      }
      onChange(value)
    }
  }

  return (
    <div className={`w-full ${className}`}>
      {label && (
        <label htmlFor={id} className="text-white text-sm py-1 px-2 rounded-full block mb-1 font-bold bg-turquoise w-full">
          {typeof label === 'string' ? label : placeholder}
        </label>
      )}
      <input
        id={id}
        ref={useRef}
        disabled={disabled || hiddenDisabled}
        required={required}
        type={type}
        max={max}
        value={prepValue()}
        onChange={(e) => (returnRawChangeEvent ? onChange(e) : internalOnChange(e.target.value))}
        placeholder={placeholder}
        className={`placeholder-gray w-full py-1 px-2 bg-lightGray text-black rounded-lg focus:outline-none focus:shadow-outline disabled:bg-transparent`}
        autoComplete={autoComplete}
      />
    </div>
  )
}

export default Input
