import { Level } from './Auth'
import { Beteiligung } from './Beteiligung'
import { File } from './generic'

export type Login = {
  identifier?: string
  password?: string
  level?: Level
}

export enum Titel {
  DR = 'DR',
  DRDR = 'DRDR',
  PROFDR = 'PROFDR',
  PROFDRDR = 'PROFDRDR',
}

export enum Ansprache {
  HERR = 'HERR',
  FRAU = 'FRAU',
  DIVERS = 'DIVERS',
}

export type Kontakt = {
  titel?: Titel
  ansprache?: Ansprache
  vorname?: string
  nachname?: string
  geburtsname?: string
  geburtsdatum?: Date
  strasseHausnummer?: string
  postleitzahl?: string
  stadt?: string
  land?: string
  telefon?: string
  mobilTelefon?: string
  mail?: string
  onliner?: boolean
}

export type Steuer = {
  steuernummer?: string
  steuerID?: string
}

export type Pfaendung = {
  aktiv?: boolean
  anfangszeitpunkt?: Date
  files?: File[]
}

export type Todesfall = {
  aktiv?: boolean
  todeszeitpunkt?: Date
  files?: File[]
}

export type Inaktivitaet = {
  inaktivitaet?: boolean
}

export type Person = {
  uuid?: string
  login: Login
  kontakt: Kontakt
  steuer: Steuer
  pfaendung: Pfaendung
  todesfall: Todesfall
  beteiligungen?: Beteiligung[]
  inaktivitaet: Inaktivitaet
}
