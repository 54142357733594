import { useSelector } from 'react-redux'
import { Tagesordnungspunkt } from '../../../../types/Versammlung/Versammlung'
import Textarea from '../../../UI/Textarea/Textarea'
import props from '../../../../redux/props'

type Props = {
  top: Tagesordnungspunkt
  value: string
  onChange: (text: string) => void
}

const CorrectionTop: React.FC<Props> = ({ top, value, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <div>
      <div className="font-bold">{t.versammlung.attributes.beschlussvorlage}</div>
      <div>{top.beschlussvorlage}</div>
      <div className="font-bold">{t.versammlung.attributes.protokoll}</div>
      <div>{top.protokoll}</div>
      <div className="mt-3">
        <Textarea rows={10} placeholder={t.versammlung.signature.protokoll.decline.placeholder} value={value} onChange={onChange} />
      </div>
    </div>
  )
}

export default CorrectionTop
