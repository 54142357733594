import { useEffect, useState } from 'react'
import { Type, Versammlung } from '../../../../../types/Versammlung/Versammlung'
import props from '../../../../../redux/props'
import { crud } from '@think-internet/zeus-frontend-package'
import { Company } from '../../../../../types/Company'
import Loading from '../../../../UI/Loading/Loading'
import { useSelector } from 'react-redux'
import useToast, { Type as ToastType } from '../../../../hooks/useToast'
import Accordion from '../../../../UI/Accordion/Accordion'
import { getPrettyDateTime } from '../../../../../utility'
import Item from './Item'
import Toggle from '../../../../UI/Toggle/Toggle'

type Props = {
  company: Company
  type: Type
}

const List: React.FC<Props> = ({ company, type }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const versammlungFeature = crud.use(props.VERSAMMLUNG)
  const [list, setList] = useState<Versammlung[]>()
  const toast = useToast()
  const [showArchived, setShowArchived] = useState(false)

  useEffect(() => {
    const pull = async () => {
      const list = await versammlungFeature.list({ companyUUID: company.uuid, type })
      if (Array.isArray(list)) {
        setList(list)
      } else {
        toast(t.versammlung.list.error.loading, ToastType.ERROR)
      }
    }
    if (!list) pull()
  })

  const updateVersammlung = (uuid: string) => (versammlung: Versammlung) => {
    setList((list) => list.map((v) => (v.uuid === uuid ? versammlung : v)))
  }

  const itemFilter =
    (past = false) =>
    (v: Versammlung) => {
      if (!showArchived && v.isArchived) return false
      if (past) {
        return new Date(v.datumUhrzeit) < new Date()
      } else {
        return !v.datumUhrzeit || new Date(v.datumUhrzeit) > new Date()
      }
    }

  const getItems = (past = false) =>
    list.filter(itemFilter(past)).map((v, i) => ({
      title: getPrettyDateTime(v.datumUhrzeit),
      content: <Item company={company} type={type} versammlung={v} updateCallback={updateVersammlung(v.uuid)} />,
      id: i,
    }))

  if (!list) {
    return (
      <div className="flex flex-col items-center">
        <Loading loading={false} />
        <div className="text-sm">{t.versammlung.list.loading}</div>
      </div>
    )
  }
  return (
    <div className="flex flex-col gap-3">
      <Toggle label={t.versammlung.archive.showArchived} value={showArchived} onChange={setShowArchived} />
      {getItems().length > 0 && (
        <div>
          <div className="font-bold">{t.versammlung.list.upcoming}</div>
          <Accordion items={getItems()} />
        </div>
      )}
      {getItems(true).length > 0 && (
        <div>
          <div className="font-bold">{t.versammlung.list.past}</div>
          <Accordion items={getItems(true)} />
        </div>
      )}
    </div>
  )
}

export default List
