import React from 'react'
import { ArrowRightEndOnRectangleIcon, FlagIcon } from '@heroicons/react/24/outline'
import Button from '../../../../../UI/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import useFinalize from '../hooks/useFinalize'
import useSaveEndDate from '../hooks/useSaveEndDate'
import useModerationState from '../hooks/useModerationState'
import { getPrettyDateTime } from '../../../../../../utility'
import _ from 'lodash'
import ManualPersonList from '../../../../../UI/ManualPersonList/ManualPersonList'
import { FreigabeEmpfaenger, FreigabeState } from '../../../../../../types/Versammlung/Versammlung'
import { setLocal } from '../../../../../../redux/action/local'
import { moderationStateProp } from '../../../../../../types/Versammlung/Moderation'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../../redux/routes'
import { VersammlungErgebnis } from '../../../../../../types/Versammlung/VersammlungErgebnis'
import useToast, { Type } from '../../../../../hooks/useToast'
import useGetProtocol from '../../List/hooks/useGetProtocol'

const End: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const saveEndDate = useSaveEndDate()
  const finalize = useFinalize()
  const moderationState = useModerationState()
  const dispatch = useDispatch()
  const updateUnterschriftenPersonList = functional.use(Routes.VERSAMMLUNG_UPDATE_UNTERSCHRIFTEN_PERSON_LIST)
  const startUnterschriftenProcess = functional.use(Routes.VERSAMMLUNG_START_UNTERSCHRIFTEN_PROCESS)
  const toast = useToast()
  const getProtocol = useGetProtocol(moderationState && moderationState.versammlung.uuid)

  const hasEndTime = () => {
    if (!moderationState) return false
    return !!moderationState.versammlungErgebnis.endDate
  }

  const setUnterschriftenPersonList = async (personList: FreigabeEmpfaenger[]) => {
    if (!moderationState) return false

    const ergebnis: VersammlungErgebnis = await updateUnterschriftenPersonList({ versammlungUUID: moderationState.versammlung.uuid, personList })
    const newState = _.cloneDeep(moderationState)
    if (!newState) return
    newState.versammlungErgebnis = ergebnis
    dispatch(setLocal(moderationStateProp, newState))
  }

  const allUnterschriebenReceived = () => {
    if (!moderationState) return false
    if (moderationState.versammlung.wirdExternProtokolliert) return true
    const unterschriften = moderationState.versammlungErgebnis.unterschriften
    if (!unterschriften) return false
    return (
      unterschriften.empfaenger.length > 0 &&
      unterschriften.empfaenger.every((empfaenger) => !!empfaenger.signatureBase64 && empfaenger.status === FreigabeState.ACCEPTED)
    )
  }

  const triggerUnterschriftenProcess = async () => {
    if (!moderationState) return false
    if (!!moderationState.versammlungErgebnis.endDate) {
      const result = await startUnterschriftenProcess({ versammlungUUID: moderationState.versammlung.uuid })
      if (result) {
        toast(t.versammlung.moderate.end.triggerSignatures.success, Type.SUCCESS)
      } else {
        toast(t.versammlung.moderate.end.triggerSignatures.error)
      }
    } else {
      toast(t.versammlung.moderate.end.triggerSignatures.missingDate)
    }
  }

  return (
    <div className="flex flex-row gap-10 mt-10">
      <div className="flex-1 flex flex-col justify-center items-center gap-5">
        <div className="bg-turquoise rounded-full w-40 aspect-square flex items-center justify-center">
          <ArrowRightEndOnRectangleIcon
            className={`stroke-white w-28 ${!hasEndTime() && 'cursor-pointer'}`}
            onClick={hasEndTime() ? null : saveEndDate}
          />
        </div>
        {!hasEndTime() && <Button onClick={saveEndDate} text={t.versammlung.moderate.end.saveDate.cta} />}
        {moderationState && _.has(moderationState, 'versammlungErgebnis.endDate') && (
          <div>
            {t.versammlung.moderate.end.saveDate.savedPrefix}
            <span className="font-bold">{getPrettyDateTime(moderationState.versammlungErgebnis.endDate)}</span>
          </div>
        )}
        <div className="text-sm text-center mx-auto w-2/3">{t.versammlung.moderate.end.saveDate.hint}</div>
      </div>
      <div className="flex-1 flex flex-col justify-center items-center gap-5">
        <div className="bg-blue rounded-full w-52 aspect-square flex items-center justify-center">
          <FlagIcon
            className={`stroke-white w-28 ${allUnterschriebenReceived() && 'cursor-pointer'}`}
            onClick={allUnterschriebenReceived() ? finalize : null}
          />
        </div>
        {allUnterschriebenReceived() && <Button onClick={finalize} text={t.versammlung.moderate.end.finalize.cta} />}
        {moderationState && (
          <>
            {moderationState.versammlung.wirdExternProtokolliert ? (
              <div>
                <div className="text-sm text-center mx-auto w-2/3">{t.versammlung.moderate.end.finalize.hintProtocolIsExternallyManaged}</div>
              </div>
            ) : (
              <>
                {!allUnterschriebenReceived() && <div className="text-sm text-center mx-auto w-2/3">{t.versammlung.moderate.end.finalize.hint}</div>}
                {allUnterschriebenReceived() && (
                  <div className="text-sm text-center mx-auto w-2/3">{t.versammlung.moderate.end.finalize.hintAllSignaturesReceived}</div>
                )}
                {hasEndTime() && <Button onClick={getProtocol} text={t.versammlung.list.getProtocolCTA} />}
                {moderationState && (
                  <div className="w-full">
                    {moderationState.isDemoMode && (
                      <div className="text-sm text-center mx-auto w-2/3">{t.versammlung.moderate.end.finalize.demoModePersonSelect}</div>
                    )}
                    {!moderationState.isDemoMode && (
                      <ManualPersonList
                        data={moderationState.versammlungErgebnis.unterschriften.empfaenger}
                        set={setUnterschriftenPersonList}
                        sendFreigabeMail={triggerUnterschriftenProcess}
                      />
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default End
