import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Upsert from './Upsert'
import { Funktion } from '../../../types/Funktion'

type Props = {
  funktionen: Funktion[]
  callback: (funktion: Funktion) => void
  pullList: () => void
  disabled?: boolean
}

export const getName = (funktion: Funktion) => {
  if (!!funktion.targetCompany) {
    return funktion.targetCompany.base.name
  } else if (!!funktion.targetPerson) {
    return `${funktion.targetPerson.kontakt.vorname} ${funktion.targetPerson.kontakt.nachname}`
  } else {
    return ''
  }
}

const List: React.FC<Props> = ({ funktionen, callback, pullList, disabled = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div>
      <div className="flex flex-row gap-3 w-full">
        <label className="w-1/2 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.name}</label>
        <label className="w-1/2 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.typeOfFunktion.title}</label>
        {!disabled && <div className="w-20 shrink-0 truncate"></div>}
      </div>
      <div className="flex flex-col gap-1">
        {Array.isArray(funktionen) && funktionen.length > 0 ? (
          funktionen.map((funktion, index) => (
            <div className="flex flex-row gap-3 w-full" key={index}>
              <div className="w-1/2 truncate">{getName(funktion)}</div>
              <div className="w-1/2 truncate">{t.attributes.typeOfFunktion[funktion.type]}</div>
              {!disabled && (
                <div className="w-20 shrink-0 truncate">
                  <Upsert initData={funktion} callback={callback} pullList={pullList} />
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="mt-2 text-center text-sm">{t.UI.funktionManager.list.empty}</div>
        )}
      </div>
    </div>
  )
}

export default List
