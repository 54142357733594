import { useSelector } from 'react-redux'
import Dropdown from '../Dropdown/Dropdown'
import props from '../../../redux/props'
import { FunktionType as FunktionTypeType } from '../../../types/Funktion'

type Props = {
  targetType: 'person' | 'company'
  value: string
  onChange: (value: string) => void
  required?: boolean
}

const FunktionType: React.FC<Props> = ({ targetType, value, onChange, required = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  if (targetType === 'company') {
    return (
      <Dropdown
        label={t.UI.funktionType.title}
        required={required}
        items={[FunktionTypeType.GESCHAEFTSFUEHRER, FunktionTypeType.BEIRAT].map((i) => ({ label: t.attributes.typeOfFunktion[i], value: i }))}
        value={value}
        onChange={onChange}
      />
    )
  }
  return (
    <Dropdown
      label={t.UI.funktionType.title}
      required={required}
      items={[FunktionTypeType.STEUERBERATER, FunktionTypeType.NIESSBRAUCH].map((i) => ({ label: t.attributes.typeOfFunktion[i], value: i }))}
      value={value}
      onChange={onChange}
    />
  )
}

export default FunktionType
