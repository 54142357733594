import { crud } from '@think-internet/zeus-frontend-package'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BuildingOffice2Icon } from '@heroicons/react/24/outline'
import props from '../../../../redux/props'
import { Company } from '../../../../types/Company'
import Button from '../../../UI/Button/Button'
import { useParams } from 'react-router-dom'
import MediumContainer from '../../../UI/Container/MediumContainer'
import SideNavigation from '../../../UI/Layout/SideNavigation/SideNavigation'
import List from './List/List'
import { FreigabeState, Type, Versammlung } from '../../../../types/Versammlung/Versammlung'
import useGetBeteiligung from '../../../hooks/useGetBeteiligung'
import { Position } from '../../../../types/Beteiligung'

const Overview = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const companyFeature = crud.use(props.COMPANY)
  const versammlungFeature = crud.use(props.VERSAMMLUNG)
  const [company, setCompany] = useState<Company>(null)
  const { companyUUID, type } = useParams()
  const gfBeteiligungen = useGetBeteiligung({
    targetCompanyUUID: companyUUID,
    position: Position.GESCHAEFTSFUEHRER,
  })

  useEffect(() => {
    const pull = async () => {
      const result = await companyFeature.get({ uuid: companyUUID })
      if (result) {
        setCompany(result)
      }
    }
    if (!company) {
      pull()
    }
  }, [company, companyFeature, companyUUID])

  const createVersammlung = async () => {
    const versammlung: Versammlung = {
      companyUUID: company.uuid,
      type: type as Type,
      freigabe: {
        empfaenger: Array.isArray(gfBeteiligungen)
          ? gfBeteiligungen.map((gf) => ({
              uuid: gf.uuid,
              vorname: gf.person.kontakt.vorname,
              nachname: gf.person.kontakt.nachname,
              mail: gf.person.kontakt.mail,
              position: Position.GESCHAEFTSFUEHRER,
              status: FreigabeState.PENDING,
            }))
          : [],
        sendFreigabeMail: false,
      },
    }
    const result = await versammlungFeature.create(versammlung)
    if (result) {
      window.location.href = `/admin/company/versammlung/${type}/upsert/${company.uuid}/${result.uuid}`
    }
  }

  if (!company) return <></>
  return (
    <div className="mt-10 pb-20">
      <MediumContainer>
        <div className="flex flex-row items-center gap-3 justify-between">
          <a href={`/admin/company/${company.uuid}`} className="overflow-hidden flex gap-3 items-center justify-start">
            <BuildingOffice2Icon className="fill-blue w-10 shrink-0" />
            <div className="font-bold text-lg text-blue truncate">{`${company.base.name} - ${t.versammlung[type]}`}</div>
          </a>
          <Button onClick={createVersammlung} text={t.versammlung.upsert.cta} className="shrink-0" />
        </div>
      </MediumContainer>
      <MediumContainer>
        <div className="relative mt-3">
          <SideNavigation uuid={company.uuid} items={t.company.navigation} />
          <List company={company} type={type as Type} />
        </div>
      </MediumContainer>
    </div>
  )
}

export default Overview
