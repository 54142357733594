import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../../redux/props'
import { useCallback, useEffect, useState } from 'react'
import useToast, { Type } from '../../hooks/useToast'
import { useSelector } from 'react-redux'
import List from './List'
import Upsert from './Upsert'
import { Funktion } from '../../../types/Funktion'

type Props = {
  personUUID: string
  disabled?: boolean
}

const FunktionManager: React.FC<Props> = ({ personUUID, disabled = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const funktionFeature = crud.use(props.FUNKTION)
  const [funktionen, setFunktionen] = useState<Funktion[]>()
  const toast = useToast()

  const pull = useCallback(async () => {
    const funktionen = await funktionFeature.list({ personUUID })
    if (Array.isArray(funktionen)) {
      setFunktionen(funktionen)
    } else {
      toast(t.UI.funktionManager.error.list, Type.ERROR)
    }
  }, [personUUID, funktionFeature, toast, t])

  useEffect(() => {
    if (!Array.isArray(funktionen)) pull()
  }, [funktionen, pull])

  const updateList = (isUpdate: boolean) => (funktion: Funktion) => {
    if (isUpdate) {
      setFunktionen((prev) => prev.map((i) => (i.uuid === funktion.uuid ? funktion : i)))
    } else {
      setFunktionen((prev) => [...prev, funktion])
    }
  }

  if (!Array.isArray(funktionen)) return <></>
  return (
    <div>
      <List funktionen={funktionen} callback={updateList(true)} pullList={pull} disabled={disabled} />
      {!disabled && (
        <div className="flex justify-end mt-3">
          <Upsert personUUID={personUUID} callback={updateList(false)} pullList={pull} />
        </div>
      )}
    </div>
  )
}

export default FunktionManager
