import { Company } from './Company'
import { Person } from './Person'
import { File } from './generic'

export enum FunktionType {
  GESCHAEFTSFUEHRER = 'GESCHAEFTSFUEHRER',
  BEIRAT = 'BEIRAT',
  STEUERBERATER = 'STEUERBERATER',
  NIESSBRAUCH = 'NIESSBRAUCH',
}

export type Funktion = {
  uuid?: string
  personUUID?: string
  targetPersonUUID?: string
  targetCompanyUUID?: string
  type?: FunktionType
  files?: File[]

  // dynamic properties
  person?: Person
  targetPerson?: Person
  targetCompany?: Company
}
