import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import Button from '../../../../../UI/Button/Button'
import Modal from '../../../../../UI/Modal/Modal'
import { SectionProps } from '../../Upsert'
import { Beschlussfaehigkeit, Company } from '../../../../../../types/Company'
import CompanySelect, { Criteria } from '../../../../../UI/CompanySelect/CompanySelect'

const AddCompany: React.FC<SectionProps<Beschlussfaehigkeit['bestimmteKomplementaereCompanyList']>> = ({ company, data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)

  const [companyList, setCompanyList] = useState<Company[]>(data || [])

  const toggle = () => setOpen(!open)

  const confirm = async () => {
    set(companyList)
    toggle()
  }

  const getCriteria = (): Criteria => {
    return { targetCompanyUUID: company?.uuid }
  }

  return (
    <>
      <Button onClick={toggle} text={t.company.kontaktpersonen.add.cta} />
      <Modal
        noForm
        large
        onConfirm={confirm}
        show={open}
        onClose={toggle}
        title={t.company.beschlussfaehigkeit.bestimmteKomplementaereCompanyList.add.title}
      >
        <div className="flex flex-col md:flex-row gap-10 md:gap-3 mt-3">
          <div className="flex-1">
            <CompanySelect criteria={getCriteria()} selected={companyList} onSelect={setCompanyList} />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AddCompany
