import { useState } from 'react'
import FileSelect from '../FileSelect/FileSelect'
import Progress from '../Progress/Progress'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { s3Upload } from '@think-internet/zeus-frontend-package/fetch/fetch'
import { File as CustomFile } from '../../../types/generic'

type PresignedUploadResponse = {
  presignedURL: string
  key: string
  url?: string
}

type Props = {
  id: string
  label?: string
  acceptedMimeTypes?: string[]
  uploadType: UPLOAD_TYPES
  onFilesCallback: (files: CustomFile[] | CustomFile) => void
  children?: React.ReactNode
  multiple?: boolean
  className?: string
  progressCallback?: (progress: number) => void
}

export enum UPLOAD_TYPES {
  COMPANY_LOGO = 'COMPANY_LOGO',
  COMPANY_KAPITALERHOEHUNG = 'COMPANY_KAPITALERHOEHUNG',
  COMPANY_INSOLVENZ = 'COMPANY_INSOLVENZ',
  PERSON_PFAENDUNG = 'PERSON_PFAENDUNG',
  PERSON_TODESFALL = 'PERSON_TODESFALL',
  FUNKTION = 'FUNKTION',
  BETEILIGUNG = 'BETEILIGUNG',
  UEBERTRAGUNG = 'UEBERTRAGUNG',
  VERSAMMLUNG_TAGESORDNUNGSPUNKT = 'VERSAMMLUNG_TAGESORDNUNGSPUNKT',
}

const DirectFileUpload: React.FC<Props> = ({
  id,
  label,
  acceptedMimeTypes,
  onFilesCallback,
  progressCallback,
  children,
  uploadType,
  className,
  multiple = false,
}) => {
  const [progress, setProgress] = useState<number>(0)
  const getImageUploadPresignedURL = functional.use(Routes.GET_FILE_UPLOAD_PRESIGNED_URL)

  const process = async (files: File[]) => {
    const outputFiles: CustomFile[] = []
    for (const file of files) {
      const response: PresignedUploadResponse = await getImageUploadPresignedURL({
        type: uploadType,
        meta: { type: file.type, name: file.name, size: file.size },
        id,
      })
      if (!response) continue
      const { presignedURL, key } = response

      const uploadResult = await s3Upload(presignedURL, file, progressCallback || setProgress)
      if (!uploadResult) continue
      outputFiles.push({ key, name: file.name, mimeType: file.type, size: file.size })
    }
    if (outputFiles.length > 0) {
      if (multiple) {
        onFilesCallback(outputFiles)
      } else {
        onFilesCallback(outputFiles[0])
      }
    }
  }

  return (
    <>
      {((!progressCallback && (progress === 0 || progress === 100)) || !!progressCallback) && (
        <FileSelect
          acceptedMimeTypes={acceptedMimeTypes}
          label={label}
          onFiles={process}
          children={children}
          multiple={multiple}
          showAllowedLabels={false}
          className={className}
        />
      )}
      {!progressCallback && <Progress progress={progress} />}
    </>
  )
}

export default DirectFileUpload
