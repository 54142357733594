import { useEffect, useState } from 'react'
import Modal from '../Modal/Modal'
import Button from '../Button/Button'
import props from '../../../redux/props'
import { crud, usePrevious } from '@think-internet/zeus-frontend-package'
import { PencilIcon } from '@heroicons/react/24/solid'
import { useSelector } from 'react-redux'
import CompanySelect from '../CompanySelect/CompanySelect'
import { Company } from '../../../types/Company'
import Toggle from '../Toggle/Toggle'
import AddFiles from '../AddFiles/AddFiles'
import { UPLOAD_TYPES } from '../DirectFileUpload/DirectFileUpload'
import { File } from '../../../types/generic'
import useToast, { Type } from '../../hooks/useToast'
import _ from 'lodash'
import RemoveConfirmation from '../RemoveConfirmation/RemoveConfirmation'
import { TrashIcon } from '@heroicons/react/24/outline'
import { Funktion } from '../../../types/Funktion'
import OptionSelect from '../OptionSelect/OptionSelect'
import PersonSelect from '../PersonSelect/PersonSelect'
import { Person } from '../../../types/Person'
import FunktionType from '../Input/FunktionType'
import { getName } from './List'

type Props = {
  personUUID?: string
  initData?: Funktion
  callback: (funktion: Funktion) => void
  pullList: () => void
}

type PreviousParameters = {
  personUUID?: string
  initData?: Funktion
}

type InternalFunktion = Funktion & { confirmation?: boolean }

const Upsert: React.FC<Props> = ({ personUUID, callback, initData = {}, pullList }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState<InternalFunktion>()
  const [open, setOpen] = useState<boolean>(false)
  const funktionFeature = crud.use(props.FUNKTION)
  const toast = useToast()
  const previousParameter: PreviousParameters = usePrevious({ personUUID, initData })
  const [targetType, setTargetType] = useState<'company' | 'person'>(!!initData ? (!!initData.targetCompanyUUID ? 'company' : 'person') : null)

  useEffect(() => {
    if (!data) {
      setData({ personUUID, ...initData, confirmation: false })
    } else if (!!previousParameter && (previousParameter.personUUID !== personUUID || !_.isEqual(previousParameter, { personUUID, initData }))) {
      setData({ personUUID, ...initData, confirmation: false })
    }
  }, [data, initData, personUUID, previousParameter])

  const confirm = async () => {
    if (!!data.confirmation) {
      if ((data.targetCompany && data.targetCompanyUUID) || (data.targetPerson && data.targetPersonUUID)) {
        const result = await funktionFeature.upsert(data)
        if (result) {
          callback(result)
          toast(t.UI.funktionManager.upsert.success, Type.SUCCESS)
          setOpen(false)
        } else {
          toast(t.UI.funktionManager.upsert.error.generic, Type.ERROR)
        }
      } else {
        toast(t.UI.funktionManager.upsert.error.missingCompanyOrPerson, Type.ERROR)
      }
    } else {
      toast(t.UI.funktionManager.upsert.error.missingConfirmation, Type.ERROR)
    }
  }

  const remove = async () => {
    const result = await funktionFeature.remove({ uuid: initData.uuid })
    if (result) {
      callback(result)
      toast(t.UI.funktionManager.upsert.remove.success, Type.SUCCESS)
      setOpen(false)
      pullList()
    } else {
      toast(t.UI.funktionManager.upsert.remove.error, Type.ERROR)
    }
  }

  const toggle = () => setOpen(!open)

  const set = (prop: keyof InternalFunktion) => (value: any) => {
    setData({ ...data, [prop]: value })
  }

  const updateTarget = (key: 'company' | 'person') => (selectedList: Company[] | Person[]) => {
    if (selectedList.length > 0) {
      if (key === 'company') {
        setData({
          ...data,
          targetCompanyUUID: selectedList[0].uuid,
          targetCompany: selectedList[0] as Company,
          targetPersonUUID: null,
          targetPerson: null,
        })
      } else if (key === 'person') {
        setData({
          ...data,
          targetPersonUUID: selectedList[0].uuid,
          targetPerson: selectedList[0] as Person,
          targetCompanyUUID: null,
          targetCompany: null,
        })
      }
    }
  }

  const updateTargetType = (value: 'company' | 'person') => {
    setTargetType(value)
    setData({ ...data, targetCompanyUUID: null, targetCompany: null, targetPersonUUID: null, targetPerson: null })
  }

  const removeFile = (file: File) => () => {
    const newFiles = data.files.filter((f) => f.key !== file.key)
    set('files')(newFiles)
  }

  if (!data) return null
  return (
    <>
      {!!initData.uuid ? (
        <div className="flex gap-3 justify-center">
          <RemoveConfirmation onConfirm={remove} text={t.UI.funktionManager.upsert.remove.text(getName(data))}>
            <TrashIcon className="h-5 w-5 stroke-red cursor-pointer" />
          </RemoveConfirmation>
          <div className="cursor-pointer" onClick={toggle}>
            <PencilIcon className="h-5 w-5 text-blue" />
          </div>
        </div>
      ) : (
        <Button onClick={toggle} text={t.UI.funktionManager.upsert.cta} />
      )}
      <Modal
        large
        onConfirm={confirm}
        show={open}
        onClose={toggle}
        title={initData.uuid ? t.UI.funktionManager.upsert.updateTitle : t.UI.funktionManager.upsert.createTitle}
      >
        <div className="flex flex-col gap-3">
          <OptionSelect
            options={[
              { name: t.UI.funktionManager.upsert.targetCompany, value: 'company' },
              { name: t.UI.funktionManager.upsert.targetPerson, value: 'person' },
            ]}
            selected={targetType}
            onSelect={updateTargetType}
          />
          {targetType === 'person' && (
            <PersonSelect
              selected={data.targetPerson ? [data.targetPerson] : []}
              onSelect={updateTarget('person')}
              excludedUUIDList={[personUUID]}
              single
            />
          )}
          {targetType === 'company' && (
            <CompanySelect selected={data.targetCompany ? [data.targetCompany] : []} onSelect={updateTarget('company')} single />
          )}
          {(data.targetCompany || data.targetPerson) && (
            <>
              <FunktionType targetType={targetType} value={data.type} onChange={set('type')} />
              <AddFiles
                id={data.personUUID}
                removeCallback={removeFile}
                uploadType={UPLOAD_TYPES.FUNKTION}
                files={data.files}
                onFiles={set('files')}
              />
              <Toggle required label={t.UI.funktionManager.upsert.checkHint} value={data.confirmation} onChange={set('confirmation')} />
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

export default Upsert
