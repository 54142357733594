import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { Company } from '../../../../../types/Company'
import { Type, Versammlung } from '../../../../../types/Versammlung/Versammlung'
import Button from '../../../../UI/Button/Button'
import useGetProtocol from './hooks/useGetProtocol'
import _ from 'lodash'
import Broadcast from './Broadcast'
import CopyVersammlung from './CopyVersammlung'
import ArchiveVersammlung from './ArchiveVersammlung'

type Props = { type: Type; company: Company; versammlung: Versammlung; updateCallback: (versammlung: Versammlung) => void }

const Item: React.FC<Props> = ({ type, company, versammlung, updateCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const getProtocol = useGetProtocol(versammlung.uuid)

  const isFinalized = _.has(versammlung, 'ergebnis.isFinalized') ? versammlung.ergebnis.isFinalized : false

  return (
    <div>
      <div className="flex flex-row gap-2 justify-end">
        {isFinalized ? (
          <>
            <ArchiveVersammlung versammlung={versammlung} updateCallback={updateCallback} />
            <CopyVersammlung versammlungUUID={versammlung.uuid} />
            <Button onClick={getProtocol} text={t.versammlung.list.getProtocolCTA} />
            <Broadcast versammlungUUID={versammlung.uuid} />
          </>
        ) : (
          <>
            <ArchiveVersammlung versammlung={versammlung} updateCallback={updateCallback} />
            <CopyVersammlung versammlungUUID={versammlung.uuid} />
            <Button href={`/admin/company/versammlung/${type}/upsert/${company.uuid}/${versammlung.uuid}`} text={t.versammlung.list.editCTA} />
            {versammlung.tagesordnungspunkte?.length > 0 && (
              <Button
                href={`/admin/company/versammlung/${type}/moderate/${company.uuid}/${versammlung.uuid}`}
                text={t.versammlung.list.moderateCTA}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Item
