import { Property, Required } from './types'

export const base: Property[] = [
  {
    name: 'name',
    required: Required.NEEDED,
  },
  {
    name: 'rechtsform',
    required: Required.NEEDED,
  },
  {
    name: 'mail',
    required: Required.NEEDED,
  },
  {
    name: 'telefon',
    required: Required.OPTIONAL,
  },
  {
    name: 'mobilTelefon',
    required: Required.OPTIONAL,
  },
  {
    name: 'strasseHausnummer',
    required: Required.NEEDED,
  },
  {
    name: 'postleitzahl',
    required: Required.NEEDED,
  },
  {
    name: 'stadt',
    required: Required.NEEDED,
  },
  {
    name: 'land',
    required: Required.NEEDED,
  },
  {
    name: 'onliner',
    required: Required.NEEDED,
  },
]

export const steuer: Property[] = [
  {
    name: 'steuernummer',
    required: Required.NEEDED,
  },
  {
    name: 'umsatzsteuerID',
    required: Required.OPTIONAL,
  },
  {
    name: 'mandantennummer',
    required: Required.NEEDED,
  },
]

export const kapitalisierung: Property[] = [
  {
    name: 'initialKapital',
    required: Required.NEEDED,
  },
  {
    name: 'stimmenGesamt',
    required: Required.NEEDED,
  },
]

export const bank: Property[] = [
  {
    name: 'iban',
    required: Required.NEEDED,
  },
  {
    name: 'bic',
    required: Required.NEEDED,
  },
]
export const logo: Property[] = [
  {
    name: 'key',
    required: Required.NEEDED,
  },
]
export const mehrheit: Property[] = [
  {
    name: 'enthaltungenImSchriftlichenAbstimmungsverfahren',
    required: Required.NEEDED,
  },
  {
    name: 'enthaltungenBeiGesellschafterversammlungen',
    required: Required.NEEDED,
  },
  {
    name: 'custom',
    required: Required.NEEDED,
  },
]
export const administratoren: Property[] = [
  {
    name: 'primaer',
    required: Required.NEEDED,
  },
  {
    name: 'sekundaer',
    required: Required.NEEDED,
  },
]
export const einladungsfrist: Property[] = [
  {
    name: 'inTagen',
    required: Required.NEEDED,
  },
]
