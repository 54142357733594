import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { Company } from '../../../types/Company'

type Props = {
  list: Company[]
}

const CompanyList: React.FC<Props> = ({ list }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <div>
      <div className="flex flex-row gap-10 w-full">
        <label className="w-2/4 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.name}</label>
        <label className="w-1/4 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.mail}</label>
        <label className="w-1/4 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.telefon}</label>
      </div>
      <div className="flex flex-col gap-1">
        {Array.isArray(list) && list.length > 0 ? (
          list.map((company, index) => (
            <div className="flex flex-row gap-10 w-full" key={index}>
              <div className="w-2/4 truncate">{company.base.name}</div>
              <div className="w-1/4 truncate">{company.base.mail}</div>
              <div className="w-1/4 truncate">{company.base.telefon || company.base.mobilTelefon}</div>
            </div>
          ))
        ) : (
          <div className="mt-2 text-center text-sm">{t.UI.companyList.empty}</div>
        )}
      </div>
    </div>
  )
}

export default CompanyList
