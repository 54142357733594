import { Beteiligung } from '../Beteiligung'
import { FreigabeEmpfaenger } from './Versammlung'

export enum AbstimmungDefault {
  ZUSTIMMUNG = 'ZUSTIMMUNG',
  ABLEHNUNG = 'ABLEHNUNG',
  ENTHALTUNG = 'ENTHALTUNG',
}

export type AbstimmungOptionUUID = string

export type Abstimmung = AbstimmungDefault | AbstimmungOptionUUID

export type Ergebnis = {
  beteiligungUUID?: string
  anwesend?: boolean
  abstimmung?: Abstimmung
}

export type Tagesordnungspunkt = {
  key: string
  ergebnisse?: Ergebnis[]
  protokoll?: string
}

export type UnterschriftenEmpfaenger = FreigabeEmpfaenger & {
  signatureBase64?: string
  correction?: string[]
}

export type Unterschriften = {
  empfaenger?: UnterschriftenEmpfaenger[]
  sendFreigabeMail?: boolean
}

export type VersammlungErgebnis = {
  uuid?: string
  versammlungUUID?: string
  absoluteTeilnehmer?: Beteiligung[]
  tagesordnungspunkte?: Tagesordnungspunkt[]
  startDate?: Date
  endDate?: Date
  unterschriften?: Unterschriften
  isFinalized?: boolean
  protocolPDFKey?: string
}
