import { functional } from '@think-internet/zeus-frontend-package'
import Button from '../../../../UI/Button/Button'
import Routes from '../../../../../redux/routes'
import { Versammlung } from '../../../../../types/Versammlung/Versammlung'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import useToast from '../../../../hooks/useToast'

type Props = {
  versammlungUUID: string
}

const CopyVersammlung: React.FC<Props> = ({ versammlungUUID }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()
  const runCopy = functional.use(Routes.VERSAMMLUNG_COPY)

  const run = async () => {
    const versammlung: Versammlung = await runCopy({ versammlungUUID })
    if (!!versammlung) {
      window.location.href = `/admin/company/versammlung/${versammlung.type}/upsert/${versammlung.companyUUID}/${versammlung.uuid}`
    } else {
      toast(t.versammlung.copy.error)
    }
  }

  return <Button onClick={run} text={t.generic.copy} />
}

export default CopyVersammlung
