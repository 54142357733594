import props from '../../../../../../redux/props'
import { useSelector } from 'react-redux'
import { SectionProps } from '../../Upsert'
import { Beschlussfaehigkeit as BeschlussfaehigkeitType, Company } from '../../../../../../types/Company'
import Toggle from '../../../../../UI/Toggle/Toggle'
import Section from '../../../../../UI/Accordion/Section/Section'
import Textarea from '../../../../../UI/Textarea/Textarea'
import Number from '../../../../../UI/Input/Number'
import { Person } from '../../../../../../types/Person'
import PersonList from '../../../../../UI/PersonList/PersonList'
import Currency from '../../../../../UI/Input/Currency'
import { formatEUR, formatNumber } from '../../../../../../utility'
import AddPerson from './AddPerson'
import AddCompany from './AddCompany'
import CompanyList from '../../../../../UI/CompanyList/CompanyList'

const Beschlussfaehigkeit: React.FC<SectionProps<BeschlussfaehigkeitType>> = ({ company, data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const internalSet = (key: keyof BeschlussfaehigkeitType) => (value: any) => {
    const newData = { ...data, [key]: value }
    set(newData)
  }

  const setBestimmteKomplementaerePerson = (personList: Person[]) => {
    const uuidList = personList.map((person) => person.uuid)
    const newData: Pick<BeschlussfaehigkeitType, 'bestimmteKomplementaerePersonUUIDList' | 'bestimmteKomplementaerePersonList'> = {
      ...data,
      bestimmteKomplementaerePersonUUIDList: uuidList,
      bestimmteKomplementaerePersonList: personList,
    }
    set(newData)
  }

  const setBestimmteKomplementaereCompany = (companyList: Company[]) => {
    const uuidList = companyList.map((company) => company.uuid)
    const newData: Pick<BeschlussfaehigkeitType, 'bestimmteKomplementaereCompanyUUIDList' | 'bestimmteKomplementaereCompanyList'> = {
      ...data,
      bestimmteKomplementaereCompanyUUIDList: uuidList,
      bestimmteKomplementaereCompanyList: companyList,
    }
    set(newData)
  }

  const getBestimmteKomplementaerePersonListLabel = () => {
    const personAmount = data.bestimmteKomplementaerePersonUUIDList ? data.bestimmteKomplementaerePersonUUIDList.length : 0
    const companyAmount = data.bestimmteKomplementaereCompanyUUIDList ? data.bestimmteKomplementaereCompanyUUIDList.length : 0
    const totalAmount = personAmount + companyAmount
    if (totalAmount === 0) {
      return `0 ${t.generic.personen}`
    } else if (totalAmount === 1) {
      return `1 ${t.generic.person}`
    } else {
      return `${totalAmount} ${t.generic.personen}`
    }
  }

  return (
    <div className="flex flex-col gap-2">
      <Toggle label={t.attributes.keineVoraussetzungen} value={data['keineVoraussetzungen']} onChange={internalSet('keineVoraussetzungen')} />
      {!data['keineVoraussetzungen'] && (
        <>
          <Section title={`${t.company.beschlussfaehigkeit.sections.minKapital}${formatEUR(data.minKapital)}`} light>
            <Currency
              max={company?.dynamic?.kapitalVerwendet || null}
              label
              placeholder={t.attributes.minKapital}
              value={data.minKapital}
              onChange={internalSet('minKapital')}
            />
          </Section>
          <Section title={`${t.company.beschlussfaehigkeit.sections.minKommanditisten}${formatNumber(data.minKommanditisten)}`} light>
            <Number
              max={company?.dynamic?.numberOfKomplementaer + company?.dynamic?.numberOfGesellschafter || null}
              label
              placeholder={t.attributes.minKommanditisten}
              value={data.minKommanditisten}
              onChange={internalSet('minKommanditisten')}
            />
          </Section>
          <Section title={`${t.company.beschlussfaehigkeit.sections.minKomplementaere}${formatNumber(data.minKomplementaere)}`} light>
            <Number
              max={company?.dynamic?.numberOfKomplementaer || null}
              label
              placeholder={t.attributes.minKomplementaere}
              value={data.minKomplementaere}
              onChange={internalSet('minKomplementaere')}
            />
          </Section>
          <Section title={`${t.company.beschlussfaehigkeit.sections.minStimmen}${formatNumber(data.minStimmen)}`} light>
            <Number
              max={company?.dynamic?.stimmenVerwendet || null}
              label
              placeholder={t.attributes.minStimmen}
              value={data.minStimmen}
              onChange={internalSet('minStimmen')}
            />
          </Section>
          <Section title={`${t.company.beschlussfaehigkeit.sections.bestimmteKomplementaere}${getBestimmteKomplementaerePersonListLabel()}`} light>
            <PersonList list={data.bestimmteKomplementaerePersonList} />
            <div className="flex justify-end mt-3 mb-10">
              <AddPerson data={data.bestimmteKomplementaerePersonList} set={setBestimmteKomplementaerePerson} company={company} />
            </div>
            <CompanyList list={data.bestimmteKomplementaereCompanyList} />
            <div className="flex justify-end mt-3">
              <AddCompany data={data.bestimmteKomplementaereCompanyList} set={setBestimmteKomplementaereCompany} company={company} />
            </div>
          </Section>
        </>
      )}
      <Section title={t.company.beschlussfaehigkeit.sections.hinweis} light>
        <Textarea placeholder={t.attributes.hinweis} value={data.hinweis} onChange={internalSet('hinweis')} />
      </Section>
    </div>
  )
}

export default Beschlussfaehigkeit
