import { crud } from '@think-internet/zeus-frontend-package'
import Button from '../../../../UI/Button/Button'
import { Versammlung } from '../../../../../types/Versammlung/Versammlung'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import useToast from '../../../../hooks/useToast'

type Props = {
  versammlung: Versammlung
  updateCallback: (versammlung: Versammlung) => void
}

const ArchiveVersammlung: React.FC<Props> = ({ versammlung, updateCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()
  const versammlungFeature = crud.use(props.VERSAMMLUNG)

  const run = async () => {
    const updatedVersammlung: Versammlung = await versammlungFeature.update({ uuid: versammlung.uuid, isArchived: !versammlung.isArchived })
    if (!!updatedVersammlung) {
      updateCallback(updatedVersammlung)
    } else {
      toast(t.versammlung.copy.error)
    }
  }

  return <Button onClick={run} text={versammlung.isArchived ? t.generic.moveOutOfArchive : t.generic.moveToArchive} />
}

export default ArchiveVersammlung
