import { crud } from '@think-internet/zeus-frontend-package'

const props = {
  ...crud.Features,

  COMPANY: 'COMPANY',
  PERSON: 'PERSON',
  FUNKTION: 'FUNKTION',
  BETEILIGUNG: 'BETEILIGUNG',
  UEBERTRAGUNG: 'UEBERTRAGUNG',
  VERSAMMLUNG: 'VERSAMMLUNG',
  VERSAMMLUNG_ERGEBNIS: 'VERSAMMLUNG_ERGEBNIS',
  TODO: 'TODO',
  STORAGE_TEMPLATE_FOLDER: 'STORAGE_TEMPLATE_FOLDER',
  STORAGE_ITEM: 'STORAGE_ITEM',
}

export default props
